import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'

import Layout from '../../components/layout'
import Hero from '../../components/hero-image'
import HeroBar from '../../components/hero-bar'
import ProductOptions from '../../components/product-options'
import ProductFeatures from '../../components/product-features'

import ProductHero from './stormproof-window.jpg'

import WeatherSeals from './feature-dual-weather-seals-stormproof.png'
import EgressHinges from './feature-egress-hinges.png'
import MultiPoint from './feature-multi-point-locking.png'
import ToughenedGlass from './feature-24mm-glazing.png'

const PerformanceStorm = () => (
  <Layout>
    <Helmet title="Performance Storm Windows | Dual weather seals for even the most exposed locations | Rudd Joinery">
      <meta name="description" content="Our Performance Storm windows are manufactured using the very best quality engineered timber and hardware to provide an excellent combination of thermal performance and secure design incorporating dual weatherseals to further prevent weather ingress in exposed locations" />
    </Helmet>
    <Hero heroImage="performance-storm-hero" heroId="hero" nextId="description">
      <h1>Performance Storm</h1>
      <h2>Dual weather seals for even the most exposed locations</h2>
    </Hero>
    <div className="section" id="description">
      <div className="wrapper">
        <div
          className="story text-block"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <p>
            Our <strong>Performance Storm</strong> timber windows are
            manufactured using the very best quality engineered timber and
            hardware to provide an excellent combination of thermal performance
            and secure design incorporating dual weather seals to further
            prevent weather ingress in exposed locations.
          </p>
          <p>
            Manufactured using cutting edge technology to meet our customers'
            expectations and requirements supplying superior quality
            and offering the highest level of performance.
          </p>
          <p>
            <img 
              src={ProductHero} 
              alt="Rudd Joinery | Performance Storm" 
              className="product-hero"
              data-sal-duration="600"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out-bounce"
            />
          </p>
        </div>
      </div>
    </div>
    <ProductFeatures>
      <div className="cta-bar sticky greenblue">
        <div className="expanded">
          <h3>Our performance storm features</h3>
          <ul>
            <li>Dual high performance weather seals</li>
            <li>Multi-point espag Locking</li>
            <li>Egress easy clean hinges</li>
            <li>24mm toughened argon filled double glazing</li>
          </ul>
        </div>
      </div>
      <div className="feature-bar">
        <div className="block c__5">
          <h2>Dual weather seals</h2>
          <p>
            Featuring dual weather seals to further prevent weather ingress in
            exposed locations.
          </p>
        </div>
        <img src={WeatherSeals} alt="Dual weather seals" className="c__2"  />
      </div>
      <div className="feature-bar lightgrey">
        <div className="block c__1">
          <h2>Multi-point locking</h2>
          <p>
            Multi-point locking espagnolette for side hung or top hung
            applications.
          </p>
        </div>
        <img src={MultiPoint} alt="Multi-point locking" className="c__3" />
      </div>
      <div className="feature-bar">
        <div className="block c__7">
          <h2>Easy clean hinges</h2>
          <p>
            The hinge initially opens to the egress position allowing safe exit
            in the event of an emergency and with a simple one handed operation to
            release the hinge mechanism can be opened to it’s easy clean position.
          </p>
        </div>
        <img src={EgressHinges} alt="Easy clean hinges" className="c__1" />
      </div>
      <div className="feature-bar lightgrey">
        <div className="block c__1">
          <h2>24mm toughened double glazing</h2>
          <p>
            High energy efficiency is further increased by argon filled double
            glazing.
          </p>
        </div>
        <img src={ToughenedGlass} alt="24mm toughened double glazing" className="c__5" />
      </div>
      <div className="cta-bar greenblue">
        <div className="expanded">
          <p>
            Our <strong>Performance Storm</strong> windows can be installed
            alongside our flush casement windows and doors, as all our mouldings
            are complimented across our product range.
          </p>
        </div>
      </div>
    </ProductFeatures>
    <HeroBar imageName="performance-storm-hero" />
    <div className="section greenblue">
      <div className="wrapper">
        <div className="story text-block">
          <h2 className="heading">Performance storm options</h2>
        </div>
        <ProductOptions ProductName="Performance storm" />
      </div>
    </div>
    <div className="section lightgrey eco-friendly">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2 className="heading">Performance storm</h2>
          <p>
            Our bespoke <strong>Performance Storm</strong> window can be
            manufactured to your specific requirements. If you need help, please
            do not hesitate to contact us on <strong>01485 529136</strong> or
            fill out our <Link to="/contact-us#contact-us">contact form</Link>.
          </p>
          <p>
            <Link to="/contact-us" className="cta">
              Contact us
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default PerformanceStorm